@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.Contact-container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  overflow-x: hidden !important;
}

@media (max-width: 640px) {
  .Contact-container {
    margin-top: 3vh;
  }
}

.Contact-container .Contact-box {
  width: 80vw;
  height: 80vh;
  margin-top: 10vh;
  border: 3px solid #4b4b4b93;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 0 15px black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box {
    height: 50vh;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    margin-bottom: 40px;
  }
}

.Contact-container .Contact-box .Contact-box-left {
  width: 43%;
  height: 80%;
  margin-left: 7%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 5px dotted #4b4b4b93;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-left {
    width: 43%;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-left {
    border-right: none;
    width: 100%;
    margin-top: 20px;
    margin-left: 40px;
  }
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-left-title {
  margin: 0;
  font-size: 4.36rem;
  text-align: left;
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-left-title .Contact-box-left-title-yellow {
  color: #FFCE00;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-left-title {
    font-size: 2.36rem;
    width: auto;
  }
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-left-logos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-left-logos {
    margin-top: 10px;
  }
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-left-logos .Contact-box-left-logo-container {
  color: #F2F2F2;
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-left-logos .Contact-box-left-logo-container .Contact-box-left-logo {
  height: 40px;
  margin-left: 7px;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-left-logos .Contact-box-left-logo-container .Contact-box-left-logo {
    height: 30px;
    margin-left: 10px;
  }
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-form {
    width: 85%;
  }
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textfield {
  width: 95%;
  height: 30px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  padding: 2px 5px 2px 15px;
  color: whitesmoke;
  background-color: #000000;
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textfield::placeholder {
  color: #9D9D9E;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textfield {
    font-size: 0.7rem;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textfield {
    background-color: #565656;
    width: 93%;
  }
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textarea {
  min-width: 95%;
  min-height: 120px;
  border-radius: 5px;
  margin-top: 20px;
  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 5px 2px 15px;
  color: whitesmoke;
  background-color: #000000;
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textarea::placeholder {
  color: #9D9D9E;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textarea {
    font-size: 0.7rem;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-textarea {
    background-color: #565656;
    width: 93%;
  }
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-button {
  width: 80px;
  height: 35px;
  margin-top: 20px;
  background-color: #3E3E3E;
  border: none;
  border-radius: 5px;
  color: #62BA46;
  font-weight: 800;
  cursor: pointer;
  transition: 0.2s;
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-button:hover {
  color: #3E3E3E;
  background-color: #62BA46;
  border: 1px solid #62BA46;
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-button:hover:disabled {
  background-color: #3E3E3E;
  border: none;
}

.Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-button:disabled {
  color: #C45956;
  cursor: default;
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-left .Contact-box-form .Contact-box-button {
    margin-bottom: 20px;
    width: 100px;
  }
}

.Contact-container .Contact-box .Contact-box-right {
  width: 30%;
  height: 80%;
  margin-right: 9%;
  border-radius: 10px;
  background: linear-gradient(145deg, #121212, #151515);
  box-shadow: 5px 5px 8px #000000, -5px -5px 8px #262626;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-right {
    width: 35%;
    margin-right: 7%;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-right {
    width: 100%;
    margin-right: 0;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 3px solid #4b4b4b93;
    border-radius: 0px;
    background: none;
    box-shadow: none;
  }
}

.Contact-container .Contact-box .Contact-box-right .Contact-box-right-top {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-top {
    min-height: 20vh;
  }
}

.Contact-container .Contact-box .Contact-box-right .Contact-box-right-top .Contact-box-right-top-label {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 800;
  font-size: 1.86rem;
  margin-top: 10%;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-top .Contact-box-right-top-label {
    font-size: 1rem;
    text-align: center;
    margin-top: 25%;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-top .Contact-box-right-top-label {
    margin-top: 10%;
    font-size: 1.3rem;
  }
}

.Contact-container .Contact-box .Contact-box-right .Contact-box-right-top .Contact-box-right-top-logo {
  height: 80px;
  margin-left: 6px;
  margin-top: 12%;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-top .Contact-box-right-top-logo {
    height: 60px;
    margin-top: 10%;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-top .Contact-box-right-top-logo {
    margin-top: 5%;
    margin-bottom: 10%;
  }
}

.Contact-container .Contact-box .Contact-box-right .Contact-box-right-bottom {
  width: 100%;
  height: 50%;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 800;
  font-size: 1.46rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(145deg, #121212, #151515);
  box-shadow: inset 5px 5px 8px #000000, inset -5px -5px 8px #262626;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-bottom {
    font-size: 1rem;
    text-align: center;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-bottom {
    margin-top: 4%;
    font-size: 1.3rem;
    min-height: 20vh;
  }
}

.Contact-container .Contact-box .Contact-box-right .Contact-box-right-bottom .Contact-box-right-bottom-label {
  margin-bottom: 10px;
  color: aqua;
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-bottom .Contact-box-right-bottom-label {
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .Contact-container .Contact-box .Contact-box-right .Contact-box-right-bottom .Contact-box-right-bottom-label2 {
    margin-bottom: 10px;
  }
}
