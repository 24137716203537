@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.NavBar {
  position: sticky;
  margin: 0;
  padding: 0;
  z-index: 1000;
  background-color: #202124;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 40vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

@media (max-width: 1500px) {
  .NavBar {
    width: 60vw;
    transform: translateX(-33%);
  }
}

@media (max-width: 1200px) {
  .NavBar {
    width: 80vw;
    transform: translateX(-13%);
  }
}

@media (max-width: 640px) {
  .NavBar {
    display: none;
  }
}

.NavBar .Nav-title {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-family: "Rubik Doodle Shadow", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 2.4rem;
  color: white;
  display: none;
}

.NavBar .Nav-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10%;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  height: 100%;
  transition: 0.2s;
  position: relative;
}

.NavBar .Nav-tab:hover {
  color: #62BA46;
}

.NavBar .Nav-tab:hover .Nav-subtabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.NavBar .Nav-tab .Nav-subtabs {
  list-style: none;
  position: absolute;
  width: 200px;
  z-index: 1001;
  top: 100%;
  left: 0;
  background-color: #202124;
  border-radius: 0 0 10px 10px;
  display: none;
}

.NavBar .Nav-tab .Nav-subtabs .Nav-subtab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #202124;
  width: 90%;
  height: 50px;
  padding-left: 10%;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  border: 1px solid #292a2e;
}

.NavBar .Nav-tab .Nav-subtabs .Nav-subtab:hover {
  background-color: #27282c;
}

.NavBar .Nav-tab .Nav-subtabs .Nav-subtab:hover .Nav-subtab-link {
  color: #62BA46;
}

.NavBar .Nav-tab .Nav-subtabs .Nav-subtab .Nav-subtab-link {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;
  text-decoration: none;
  transition: 0.2s;
}

.NavBar .Nav-tab .Nav-subtabs .Nav-subtab .Nav-disabled-subtab {
  color: darkgray;
  cursor: default;
}

.NavBar .Nav-tab .Nav-subtabs .Nav-subtab .Nav-disabled-subtab:hover {
  color: darkgray;
}

.NavBar .Nav-disabled-tab {
  color: darkgray;
  cursor: default;
}

.NavBar .Nav-disabled-tab:hover {
  color: darkgray;
}

.NavBar-mobile {
  display: none;
  transition: 0.3s;
}

@media (max-width: 640px) {
  .NavBar-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 1000;
    background-color: transparent;
    top: 2.5%;
    right: 5%;
  }
  .NavBar-mobile .NavBar-mobile-button {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    justify-content: center;
    background-color: #000000;
    color: #F2F2F2;
    border-radius: 10px;
    font-size: 1.3rem;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    border: none;
    background: linear-gradient(145deg, #121212, #151515);
    box-shadow: 5px 5px 8px #000000, -5px -5px 8px #262626;
  }
}

@media (max-width: 640px) and (max-width: 356px) {
  .NavBar-mobile .NavBar-mobile-button {
    width: 40px;
    height: 40px;
    font-size: 1.1rem;
  }
}

@media (max-width: 640px) {
  .NavBar-mobile .Nav-mobile-subtabs {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
  }
  .NavBar-mobile .Nav-mobile-subtabs .Nav-mobile-subtab {
    background-color: black;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(242, 242, 242, 0.1);
    list-style: none;
  }
  .NavBar-mobile .Nav-mobile-subtabs .Nav-mobile-subtab .Nav-subtab-link {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: white;
    text-decoration: none;
    transition: 0.2s;
    margin-left: 15px;
  }
  .NavBar-mobile .Nav-mobile-subtabs .Nav-mobile-subtab .Nav-disabled-subtab {
    color: darkgray;
    cursor: default;
  }
  .NavBar-mobile .Nav-mobile-subtabs .Nav-mobile-subtab .Nav-disabled-subtab:hover {
    color: darkgray;
  }
}

@media (max-width: 640px) and (max-width: 356px) {
  .NavBar-mobile .Nav-mobile-subtabs .Nav-mobile-subtab {
    font-size: 0.8rem;
  }
}

/*.NavBar {
    position: sticky;
    margin: 0;
    padding: 0;
    z-index: 1000;
    background-color: #202124;
    top: 45%;
    left: 0;
    width: 180px;//216px; //9vw;
    height: 295px;//25vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: 2px solid black;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    .Nav-title {
        margin-top: 20px;
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-family: $font-rubik;
        font-optical-sizing: auto;
        font-weight: 400;
        font-size: 2.4rem;//2.8rem;
        color: white;
    }
    .Nav-tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 10%;
        margin-top: 20px;
        color: white;
        text-decoration: none;
        font-family: $font-pop;
        font-size: 0.8rem;
        font-weight: 600;
        p {
            margin-left: 5px;
        }
    }
}*/
