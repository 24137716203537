@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.Blogs-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden !important;
}

.Blogs-container .Blogs-header {
  background-color: #141414;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Blogs-container .Blogs-header .Blogs-title {
  width: 80%;
  font-size: 4rem;
  text-align: left;
  font-family: "Nunito Sans", sans-serif;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-header .Blogs-title {
    font-size: 2rem;
    margin-right: 5%;
  }
}

@media (max-width: 640px) {
  .Blogs-container .Blogs-header .Blogs-title {
    margin-top: 22%;
  }
}

.Blogs-container .Blogs-header .Blogs-info {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-header .Blogs-info {
    margin-right: 5%;
  }
}

@media (max-width: 640px) {
  .Blogs-container .Blogs-header .Blogs-info {
    margin-bottom: 30px;
  }
}

.Blogs-container .Blogs-header .Blogs-info .Blogs-info-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 3rem;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-header .Blogs-info .Blogs-info-left {
    justify-content: flex-start;
  }
}

.Blogs-container .Blogs-header .Blogs-info .Blogs-info-left .Blogs-author {
  font-size: 1.2rem;
}

.Blogs-container .Blogs-header .Blogs-info .Blogs-info-left .Blogs-author .Blogs-author-dot {
  color: red;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-header .Blogs-info .Blogs-info-left .Blogs-author {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
}

.Blogs-container .Blogs-header .Blogs-info .Blogs-info-left .Blogs-date {
  font-size: 1rem;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-header .Blogs-info .Blogs-info-left .Blogs-date {
    font-size: 0.8rem;
    font-weight: 400;
  }
}

.Blogs-container .Blogs-body {
  width: 100%;
  min-height: 100vh;
  background-color: #F2F2F2;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  color: #141414;
}

.Blogs-container .Blogs-body .Blogs-body-canvas {
  margin-top: 40px;
  width: 60%;
  margin-left: 10%;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-body .Blogs-body-canvas {
    margin-right: 5%;
  }
}

@media (max-width: 640px) {
  .Blogs-container .Blogs-body .Blogs-body-canvas {
    width: 90%;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-subtitle {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 2rem;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 640px) {
  .Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-subtitle {
    margin-bottom: 10px;
  }
}

.Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-para {
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 2rem;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-para {
    font-size: 1rem;
  }
}

.Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-3rowimages {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-3rowimages .Blogs-body-3rowimage {
  width: 30%;
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-body .Blogs-body-canvas .Blogs-body-3rowimages .Blogs-body-3rowimage {
    margin-right: 7px;
    margin-bottom: 7px;
  }
}

.Blogs-container .Blogs-feedback {
  width: 100%;
  background-color: #F2F2F2;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  color: #141414;
}

.Blogs-container .Blogs-feedback .Blogs-feedback-title {
  margin-top: 40px;
  width: 60%;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 2rem;
  margin-left: 10%;
  margin-bottom: 0;
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form {
  width: 60%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 500px;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-feedback .Blogs-feedback-form {
    width: 85%;
  }
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textfield {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  padding: 2px 5px 2px 15px;
  color: #141414;
  background-color: white;
  font-size: 1rem;
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textfield::placeholder {
  color: darkgrey;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textfield {
    font-size: 0.7rem;
  }
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textfield {
    width: 80%;
  }
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textarea {
  min-width: 50%;
  min-height: 120px;
  border-radius: 5px;
  margin-top: 20px;
  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 5px 2px 15px;
  color: #141414;
  background-color: white;
  font-size: 1rem;
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textarea::placeholder {
  color: darkgray;
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textarea {
    font-size: 0.7rem;
  }
}

@media (max-width: 1200px) {
  .Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-textarea {
    width: 80%;
  }
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-button {
  width: 80px;
  height: 35px;
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid #62BA46;
  border-radius: 5px;
  color: #62BA46;
  font-weight: 800;
  cursor: pointer;
  transition: 0.2s;
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-button:hover {
  color: white;
  background-color: #62BA46;
  border: 1px solid #62BA46;
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-button:hover:disabled {
  background-color: transparent;
  border: 1px solid #C45956;
}

.Blogs-container .Blogs-feedback .Blogs-feedback-form .Blogs-feedback-button:disabled {
  color: #C45956;
  border: 1px solid #C45956;
  cursor: default;
}
