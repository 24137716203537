@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.App {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #F2F2F2;
  background-color: #141414;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.dashed-line {
  margin-top: 5vh;
  width: 94%;
  height: 1px;
  background-image: linear-gradient(to right, white 50%, transparent 50%);
  background-size: 35px 1px;
  opacity: 55%;
}

@media (max-width: 640px) {
  .dashed-line {
    margin-top: 3vh;
    width: 94%;
    background-image: linear-gradient(to right, white 40%, transparent 40%);
    background-size: 15px 1px;
  }
}

h1 {
  color: #F2F2F2;
  text-align: center;
  font-size: 8vw;
  width: 95%;
  padding: 0;
  margin: 0;
  margin-top: 8vh;
}

h2 {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

.fat {
  font-weight: 600;
}

.fatter {
  font-weight: 700;
}

.fattest {
  font-weight: 800;
}

.alg-800 {
  font-family: "Alegreya Sans", sans-serif;
}

.mav-800 {
  font-family: "Montserrat", sans-serif;
}

.font-openSans {
  font-family: "Open Sans", sans-serif;
}

.font-para-300 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 300;
}

.font-para-400 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 400;
}

.font-para-600 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 600;
}

.font-para-700 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 700;
}

.font-para-900 {
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 900;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.rem24 {
  font-size: 2.4rem;
}

.rem22 {
  font-size: 2.2rem;
}

.rem2 {
  font-size: 2rem;
}

.rem18 {
  font-size: 1.8rem;
}

.rem16 {
  font-size: 1.6rem;
}

.rem14 {
  font-size: 1.4rem;
}

.rem12 {
  font-size: 1.2rem;
}

.rem1 {
  font-size: 1rem;
}

.rem08 {
  font-size: 0.8rem;
}

.rem06 {
  font-size: 0.6rem;
}

.rem04 {
  font-size: 0.4rem;
}

.r-dec {
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.colour-pr {
  color: #282C34;
}

.colour-se {
  color: #62BA46;
}

.colour-bl {
  color: #141414;
}

.colour-wh {
  color: white;
}

.colour-red {
  color: red;
}

.p-l-5 {
  padding-left: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-h-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-5 {
  padding: 5px;
}

.p-h-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-10 {
  padding: 10px;
}

.p-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-15 {
  padding: 15px;
}

.p-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-20 {
  padding: 20px;
}

.p-h-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.wrapper {
  min-height: 100%;
}

.m-auto {
  margin: auto;
}

.m-t-auto {
  margin-top: auto;
}

.m-b-auto {
  margin-bottom: auto;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-h-auto {
  margin-right: auto;
  margin-left: auto;
}

.m-v-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-0 {
  margin: 0;
}

.m-h-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.m-v-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-5 {
  margin-left: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-5 {
  margin: 5px;
}

.m-h-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.m-v-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-10 {
  margin: 10px;
}

.m-h-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.m-v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-15 {
  margin: 15px;
}

.m-h-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.m-v-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-20 {
  margin: 20px;
}

.m-h-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.m-v-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-40 {
  margin: 40px;
}

.m-h-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.m-v-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-t-150 {
  margin-top: 150px;
}

.m-t-200 {
  margin-top: 200px;
}

.m-t-250 {
  margin-top: 250px;
}

.clear-button {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

.hide {
  display: none !important;
}

.seek {
  display: block !important;
}

.seekFlex {
  display: flex !important;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-left {
  justify-content: start;
}

.justify-content-right {
  justify-content: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}

.align-items-bottom {
  align-items: flex-end;
}

.flexc {
  display: flex;
  justify-content: center;
}

.flexsa {
  display: flex;
  justify-content: space-around;
}

.flexsb {
  display: flex;
  justify-content: space-between;
}

.flexeven {
  display: flex;
  justify-content: space-evenly;
}

.flexstart {
  display: flex;
  justify-content: start;
}

.flexend {
  display: flex;
  justify-content: end;
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.bg-col-pr {
  background-color: #282C34;
}

.bg-col-se {
  background-color: #62BA46;
}

.bg-col-bl {
  background-color: #141414;
}

.shadow {
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.07);
}

@media (max-width: 1200px) {
  .tab-border-right {
    border-right: 1px black solid !important;
  }
}

.rad40 {
  border-radius: 40px;
}

.rad20 {
  border-radius: 20px;
}

.rad15 {
  border-radius: 15px;
}

.rad14 {
  border-radius: 14px;
}

.rad10 {
  border-radius: 10px;
}

.rad8 {
  border-radius: 8px;
}

.rad6 {
  border-radius: 6px;
}

.rad5 {
  border-radius: 5px;
}

.border-1 {
  border-style: solid;
  border-width: 1px;
}

.border-3 {
  border-style: solid;
  border-width: 3px;
}

.border-pr {
  border-color: #282C34;
}

.border-se {
  border-color: #62BA46;
}

.border-bl {
  border-color: #141414;
}

.border-li {
  border-color: #ccc;
}

.text-l {
  text-align: left;
}

.text-r {
  text-align: right;
}

.text-c {
  text-align: center;
}

.text-j {
  text-align: justify;
}

.col1 {
  columns: 1;
}

.col2 {
  columns: 2;
}

@media (max-width: 640px) {
  .col2 {
    columns: 1;
  }
}

.col3 {
  columns: 3;
}

@media (max-width: 1200px) {
  .col3 {
    columns: 3;
  }
}

@media (max-width: 640px) {
  .col3 {
    columns: 1;
  }
}

.col4 {
  columns: 4;
}

@media (max-width: 1200px) {
  .col4 {
    columns: 3;
  }
}

@media (max-width: 640px) {
  .col4 {
    columns: 1;
  }
}

.col-dotted {
  column-rule: 1px dotted #ccc;
  column-gap: 3em;
}

.col-w-250 {
  column-width: 250px;
}

.test-black {
  background-color: black;
}

.test-yellow {
  background-color: yellow;
}

.test-cyan {
  background-color: cyan;
}

.test-green {
  background-color: lightgreen;
}

.test-white {
  background-color: white;
}

.test-gray {
  background-color: lightgray;
}

.test-pink {
  background-color: pink;
}

.test-red {
  background-color: red;
}

.test-blue {
  background-color: blue;
}

.test-widthBig {
  width: 80%;
}

.test-widthSmall {
  width: 20%;
}

.ht-50vh {
  height: 50vh;
}

.ht-200 {
  height: 200px;
}

.ht-175 {
  height: 175px;
}

.ht-150 {
  height: 150px;
}

.ht-125 {
  height: 125px;
}

.ht-100percent {
  height: 100%;
}

.ht-100 {
  height: 100px;
}

.ht-75 {
  height: 75px;
}

.ht-50 {
  height: 50px;
}

.wt-50vw {
  width: 50vw;
}

.wt-200 {
  width: 200px;
}

.wt-175 {
  width: 175px;
}

.wt-150 {
  width: 150px;
}

.wt-125 {
  width: 125px;
}

.wt-100percent {
  width: 100%;
}

.wt-100 {
  width: 100px;
}

.wt-75 {
  width: 75px;
}

.wt-50 {
  width: 50px;
}
