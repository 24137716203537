@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.Bucketlist-container {
  cursor: pointer;
  margin-top: 33px;
  height: 70vh;
  aspect-ratio: 1/1.246;
  border: 1px solid #3D3E46;
  background-color: #3D3E46;
  border-radius: 25px;
  position: relative;
  clip-path: polygon(0 0, 47% 0%, 50% 3%, 53% 0, 100% 0%, 100% 45%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .Bucketlist-container {
    border-radius: 15px;
  }
}

@media (max-width: 640px) {
  .Bucketlist-container {
    width: 99vw;
    border-radius: 0px;
  }
}

.Bucketlist-container .Bucketlist-bubble {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  line-height: 100px;
  text-align: center;
  background-color: #1D1E2B;
  border-radius: 26px;
  clip-path: polygon(0 0, 46.7% 0%, 50% 3.3%, 53.3% 0, 100% 0%, 100% 45%, 100% 100%, 100% 100%, 0 100%);
}

@media (max-width: 1200px) {
  .Bucketlist-container .Bucketlist-bubble {
    border-radius: 15px;
  }
}

@media (max-width: 640px) {
  .Bucketlist-container .Bucketlist-bubble {
    border-radius: 0px;
  }
}

.Bucketlist-container .Bucketlist-box {
  width: 80%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10;
}

.Bucketlist-container .Bucketlist-box .Bucketlist-box-title {
  margin: 0;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  font-weight: 800;
  font-size: 1.6rem;
  text-transform: lowercase;
}

@media (max-width: 1200px) {
  .Bucketlist-container .Bucketlist-box .Bucketlist-box-title {
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
  }
}

.Bucketlist-container .Bucketlist-box .Bucketlist-box-scroller {
  margin: 0;
  width: 100%;
  height: 80%;
  overflow-y: auto;
}

@media (max-width: 1200px) {
  .Bucketlist-container .Bucketlist-box .Bucketlist-box-scroller {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.Bucketlist-container .Bucketlist-box .Bucketlist-box-scroller .Bucketlist-item {
  margin-left: 5%;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 1200px) {
  .Bucketlist-container .Bucketlist-box .Bucketlist-box-scroller .Bucketlist-item {
    font-size: 0.9rem;
  }
}

.Bucketlist-container .Bucketlist-box .Bucketlist-box-scroller .progressBar {
  margin-top: 15px;
  margin-bottom: 25px;
  margin-left: 5%;
  height: 6px;
  width: 90%;
  background-color: black;
  border-radius: 4px;
  position: relative;
}

.Bucketlist-container .Bucketlist-box .Bucketlist-box-scroller .progressBar .progressBar-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #3EC18E;
  border-radius: 4px;
}
