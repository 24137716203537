@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.PickVibe-container {
  width: 75vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .PickVibe-container {
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-size: 2.8rem;
  }
}

@media (max-width: 640px) {
  .PickVibe-container {
    width: 100vw;
  }
}

.PickVibe-container .PickVibe-title {
  font-size: 4rem;
}

@media (max-width: 1200px) {
  .PickVibe-container .PickVibe-title {
    font-size: 2.8rem;
  }
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-title {
    font-size: 2.0rem;
  }
}

.PickVibe-container .PickVibe-vibes-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5vh;
  border: 1px solid #70707093;
  border-radius: 40px;
  padding: 50px;
}

@media (max-width: 1200px) {
  .PickVibe-container .PickVibe-vibes-container {
    border-radius: 10px;
    padding: 0px;
  }
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-vibes-container {
    border-radius: 0px;
    border-left: none;
    border-right: none;
    padding-bottom: 33px;
  }
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 50px;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .PickVibe-container .PickVibe-vibes-container .PickVibe-vibe {
    margin-right: 10px;
    margin-bottom: 10px;
    scale: 0.8;
  }
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-vibes-container .PickVibe-vibe {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 33px;
    scale: 1;
    width: 49%;
  }
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-no {
  width: 56px;
  height: 56px;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: white;
  font-size: 2rem;
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-no {
    width: 40px;
    height: 40px;
    border: 1px solid white;
    font-size: 1.4rem;
  }
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box {
  cursor: pointer;
  margin-top: 33px;
  width: 203px;
  height: 253px;
  border: 1px solid #3D3E46;
  border-radius: 10px;
  background-color: #3D3E46;
  border-radius: 12px;
  position: relative;
  clip-path: polygon(0 0, 45% 0%, 50% 4%, 55% 0, 100% 0%, 100% 45%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box {
    width: 100%;
    height: 220px;
    border-radius: 0px;
  }
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box:hover {
  background-color: white;
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box:hover .PickVibe-vibe-title-box .PickVibe-vibe-title {
  color: white !important;
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box .PickVibe-vibe-bubble {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  position: absolute;
  top: 1px;
  left: 1px;
  line-height: 100px;
  text-align: center;
  background-color: #1D1E2B;
  border-radius: 10px;
  clip-path: polygon(0 0, 44% 0%, 50% 5%, 56% 0, 100% 0%, 100% 45%, 100% 100%, 100% 100%, 0 100%);
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box .PickVibe-vibe-bubble {
    border-radius: 0px;
  }
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box .PickVibe-vibe-title-box {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box .PickVibe-vibe-title-box {
    margin-bottom: 30px;
  }
}

.PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box .PickVibe-vibe-title-box .PickVibe-vibe-title {
  position: absolute;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0;
  color: #747586;
  font-size: 1.4rem;
  font-weight: 600;
  text-wrap: wrap;
}

@media (max-width: 640px) {
  .PickVibe-container .PickVibe-vibes-container .PickVibe-vibe .PickVibe-Vibe-box .PickVibe-vibe-title-box .PickVibe-vibe-title {
    font-size: 1.3rem;
  }
}
