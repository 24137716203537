@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.Footer-container {
  height: 200px;
  width: 100vw;
  border-top: 1px solid #70707093;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Footer-container .Footer-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80%;
  width: 92%;
}

@media (max-width: 640px) {
  .Footer-container .Footer-box {
    justify-content: center;
  }
}

.Footer-container .Footer-box .Footer-sides {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 640px) {
  .Footer-container .Footer-box .Footer-sides {
    display: none;
  }
}

.Footer-container .Footer-box .Footer-sides .Footer-tab {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  height: 100%;
  transition: 0.2s;
}

@media (max-width: 640px) {
  .Footer-container .Footer-box .Footer-sides .Footer-tab {
    font-size: 0.6rem;
  }
}

.Footer-container .Footer-box .Footer-sides .Footer-tab:hover {
  color: #62BA46;
}

.Footer-container .Footer-box .Footer-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Footer-container .Footer-box .Footer-center .Footer-center-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Footer-container .Footer-box .Footer-center .Footer-center-top .Footer-center-logos {
  height: 80px;
  text-decoration: none;
}

@media (max-width: 640px) {
  .Footer-container .Footer-box .Footer-center .Footer-center-top .Footer-center-logos {
    height: 40px;
  }
}

.Footer-container .Footer-box .Footer-center .Footer-center-bottom {
  margin-top: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-style: italic;
  font-weight: 300;
}

@media (max-width: 640px) {
  .Footer-container .Footer-box .Footer-center .Footer-center-bottom {
    font-size: 0.6rem;
  }
}
