@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.LanguageCorner-container {
  width: 100vw;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  overflow-x: hidden !important;
}

@media (max-width: 640px) {
  .LanguageCorner-container {
    margin-top: 3vh;
  }
}

.LanguageCorner-container .LanguageCorner-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 90%;
  margin-top: 2vh;
  font-size: 2.1vw;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .LanguageCorner-subtitle {
    margin-bottom: -1.5vh;
  }
}

.LanguageCorner-container .LanguageCorner-subtitle .LanguageCorner-flag {
  height: 26px;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .LanguageCorner-subtitle .LanguageCorner-flag {
    height: 15px;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .LanguageCorner-subtitle .LanguageCorner-flag {
    height: 10px;
  }
}

.LanguageCorner-container .LanguageCorner-hero {
  margin-top: 5vh;
  width: 75vw;
  height: 15.75vw;
  border: 2px solid black;
  border-radius: 20px;
  position: relative;
  margin-top: 5vh;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .LanguageCorner-hero {
    margin-top: 3vh;
    width: 90vw;
    border-radius: 10px;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .LanguageCorner-hero {
    width: 100vw;
    border-radius: 0px;
    margin-top: 3vh;
  }
}

.LanguageCorner-container .LanguageCorner-hero .LanguageCorner-hero-img {
  width: 100%;
  border-radius: 20px;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .LanguageCorner-hero .LanguageCorner-hero-img {
    border-radius: 10px;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .LanguageCorner-hero .LanguageCorner-hero-img {
    border-radius: 0px;
  }
}

.LanguageCorner-container .LanguageCorner-summary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vw;
  margin-top: 6vh;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .LanguageCorner-summary-container {
    width: 80vw;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .LanguageCorner-summary-container {
    width: 90vw;
  }
}

.LanguageCorner-container .LanguageCorner-summary-container .LanguageCorner-summary-title {
  font-size: 1.6rem;
  font-weight: 700;
  width: 100%;
}

.LanguageCorner-container .LanguageCorner-summary-container .LanguageCorner-summary-text {
  margin-top: 2vh;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  font-family: "Source Serif Pro", Georgia, Times, serif;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .LanguageCorner-summary-container .LanguageCorner-summary-text {
    font-size: 1.1rem;
    letter-spacing: 1px;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .LanguageCorner-summary-container .LanguageCorner-summary-text {
    font-size: 1rem;
    letter-spacing: 1px;
  }
}

@media (max-width: 317px) {
  .LanguageCorner-container .LanguageCorner-summary-container .LanguageCorner-summary-text {
    font-size: 0.9rem;
    letter-spacing: 1px;
  }
}

.LanguageCorner-container .LanguageCorner-language-list {
  margin-top: 5vh;
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 75vw;
  padding: 0;
}

@media (max-width: 640px) {
  .LanguageCorner-container .LanguageCorner-language-list {
    width: 90vw;
    justify-content: space-between;
  }
}

@media (max-width: 317px) {
  .LanguageCorner-container .LanguageCorner-language-list {
    width: 85%;
    margin-left: 0;
  }
}

.LanguageCorner-container .LanguageCorner-language-list .LanguageCorner-language {
  width: 150px;
  height: 230px;
  border-radius: 9px;
  margin-right: 37px;
  margin-bottom: 37px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .LanguageCorner-language-list .LanguageCorner-language {
    margin-bottom: 30px;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .LanguageCorner-language-list .LanguageCorner-language {
    margin-bottom: 20px;
    scale: 0.95;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 317px) {
  .LanguageCorner-container .LanguageCorner-language-list .LanguageCorner-language {
    width: 130px;
    height: 199px;
    margin-right: 0;
  }
}

.LanguageCorner-container .LanguageCorner-language-list .LanguageCorner-language .LanguageCorner-language-painting {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9px;
}

.LanguageCorner-container .LanguageCorner-language-list .Lc-red {
  background-color: #D53838;
}

.LanguageCorner-container .LanguageCorner-language-list .Lc-blue {
  background-color: #0D7695;
}

.LanguageCorner-container .LanguageCorner-language-list .Lc-grey {
  background-color: #D0D0D0;
}

.LanguageCorner-container .LanguageCorner-language-list .Lc-brown {
  background-color: #A06E00;
}

.LanguageCorner-container .LanguageCorner-language-list .Lc-l-blue {
  background-color: #86DEFF;
}

.LanguageCorner-container .PolyglotLogs-container {
  margin-top: 5vh;
  background-color: #F2F2F2;
  width: 80vw;
  padding: 3vw 10vw 3vw 10vw;
  min-height: 100vh;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container {
    margin-top: 7vh;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .PolyglotLogs-container {
    margin-top: 7vh;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-title {
  font-family: "Prompt", sans-serif;
  font-size: 3rem;
  font-weight: 900;
  letter-spacing: 2px;
  color: #141414;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-title {
    font-size: 2.0rem;
  }
}

@media (max-width: 317px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-title {
    font-size: 1.6rem;
    margin-top: 10px;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs {
  margin-top: 40px;
  width: 80%;
  display: grid;
  grid-template-areas: "topleft topleft topright rightside" "bottomarea bottomarea bottomarea rightside";
  grid-template-columns: 40% 10% 30%;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs {
    width: 100%;
    margin-top: 20px;
    grid-template-areas: "topleft topleft topright" "bottomarea bottomarea bottomarea" "rightside rightside rightside";
    grid-template-columns: 40% 20% 40%;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs {
    margin-top: 30px;
  }
}

@media (max-width: 317px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs {
    grid-template-areas: "topleft topleft topleft" "topright topright topright" "bottomarea bottomarea bottomarea" "rightside rightside rightside";
    grid-template-columns: 40% 20% 40%;
    width: 90%;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-author {
  font-family: "IBM Plex Serif", serif;
  font-size: 1rem;
  margin: 0;
  margin-left: 40px;
  margin-bottom: 10px;
  text-wrap: wrap;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-author {
    font-size: 0.8rem;
    margin-left: 20px;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-author .PolyglotLogs-newLogs-author-dot {
  color: red;
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-title {
  font-family: "IBM Plex Serif", serif;
  font-size: 1.4rem;
  margin: 0;
  margin-left: 40px;
  margin-bottom: 30px;
  margin-right: 40px;
  letter-spacing: 1px;
  text-wrap: wrap;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-title {
    font-size: 0.8rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-subtitle {
  font-family: "IBM Plex Serif", serif;
  font-size: 1rem;
  margin: 0;
  margin-top: -20px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 30px;
  font-style: italic;
  text-wrap: wrap;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-subtitle {
    font-size: 0.8rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-subtitle .PolyglotLogs-newLogs-subtitle-letter {
  font-size: 1.4rem;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-subtitle .PolyglotLogs-newLogs-subtitle-letter {
    font-size: 1rem;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topleft {
  height: 200px;
  grid-area: topleft;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0px 40px 40px 0px;
  color: #141414;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topleft {
    margin: 0px 20px 20px 0px;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topleft {
    height: 250px;
  }
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topleft {
    margin: 0px 0px 20px 0px;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topright {
  grid-area: topright;
  height: 200px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0px 40px 40px 0px;
  color: #141414;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  display: none;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topright {
    margin: 0px 0px 0px 0px;
  }
}

@media (max-width: 640px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topright {
    height: 250px;
  }
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-topright {
    margin: 0px 0px 20px 0px;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-bottom {
  grid-area: bottomarea;
  height: 100px;
  grid-area: bottomarea;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0px 40px 0px 0px;
  color: #141414;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  display: none;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-bottom {
    margin: 0px 0px 0px 0px;
  }
}

.LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-right {
  grid-area: rightside;
  height: 340px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0px;
  color: #141414;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  display: none;
}

@media (max-width: 1200px) {
  .LanguageCorner-container .PolyglotLogs-container .PolyglotLogs-newLogs .PolyglotLogs-newLogs-right {
    margin: 20px 0px 20px 0px;
    height: 200px;
  }
}
