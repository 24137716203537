@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.SnackBar {
  position: absolute;
  top: 5;
  right: 0;
  background-color: white;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
  padding: 10px 5px 10px 20px;
  color: #141414;
  width: 250px;
  height: 45px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Lato", sans-serif;
  font-weight: 1000;
  transition: 0.2s;
  z-index: 1003;
}

@media (max-width: 640px) {
  .SnackBar {
    bottom: 5%;
    z-index: 1002;
    height: 65px;
  }
}

@media (max-width: 317px) {
  .SnackBar {
    display: none !important;
  }
}

.SnackBar .SnackBar-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.SnackBar .SnackBar-left .SnackBar-message {
  color: #c39e09;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
}

.SnackBar .SnackBar-left .SnackBar-action {
  color: #c39e09;
  margin-top: 5px;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 1000;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 0;
}

.SnackBar .SnackBar-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.SnackBar .SnackBar-right .SnackBar-closeButton {
  background-color: transparent;
  border: none;
  color: #141414;
  cursor: pointer;
}
