@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.Career-container {
  width: 100vw;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  overflow-x: hidden !important;
}

@media (max-width: 640px) {
  .Career-container {
    margin-top: 3vh;
  }
}

.Career-container h1 {
  width: 75vw;
  font-size: 5.5rem;
  text-align: left;
}

@media (max-width: 1200px) {
  .Career-container h1 {
    width: 80vw;
    font-size: 3.5rem;
  }
}

@media (max-width: 640px) {
  .Career-container h1 {
    width: 80vw;
    font-size: 2rem;
  }
}

.Career-container .Career-hero {
  margin-top: 3vh;
  width: 75vw;
  height: 15.75vw;
  border: 2px solid black;
  border-radius: 25px;
  position: relative;
}

@media (max-width: 1200px) {
  .Career-container .Career-hero {
    width: 80vw;
    border-radius: 10px;
    margin-top: 2vh;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-hero {
    width: 100vw;
    border-radius: 0px;
  }
}

.Career-container .Career-hero .Career-hero-title {
  position: absolute;
  font-size: 4vw;
  bottom: 5%;
  left: 5.2%;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  max-width: 65%;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .Career-container .Career-hero .Career-hero-title {
    font-size: 3.6rem;
  }
}

.Career-container .Career-hero .Career-hero-img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Career-container .Career-hero .Career-hero-img {
    border-radius: 10px;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-hero .Career-hero-img {
    border-radius: 0px;
  }
}

.Career-container .Career-info-container {
  margin-top: 3vh;
  width: 75vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1.2rem;
  font-family: "Source Serif Pro", Georgia, Times, serif;
  letter-spacing: 0.5px;
}

@media (max-width: 1200px) {
  .Career-container .Career-info-container {
    width: 80vw;
    margin-top: 3vh;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-info-container {
    flex-direction: column;
    width: 85vw;
  }
}

.Career-container .Career-info-container .Career-info-left {
  width: 70%;
}

@media (max-width: 640px) {
  .Career-container .Career-info-container .Career-info-left {
    width: 100%;
  }
}

.Career-container .Career-info-container .Career-info-left .Career-summary-container {
  width: 100%;
}

.Career-container .Career-info-container .Career-info-left .Career-summary-container .Career-summary-title {
  font-weight: 800;
  font-size: 1.5rem;
}

.Career-container .Career-info-container .Career-info-left .Career-summary-container .Career-summary {
  font-weight: 400;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  .Career-container .Career-info-container .Career-info-left .Career-summary-container .Career-summary-title {
    font-size: 1.3rem;
  }
  .Career-container .Career-info-container .Career-info-left .Career-summary-container .Career-summary {
    font-size: 0.9rem;
    letter-spacing: 0.5px;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-info-container .Career-info-left .Career-summary-container {
    text-align: justify;
  }
  .Career-container .Career-info-container .Career-info-left .Career-summary-container .Career-summary-title {
    font-size: 1.2rem;
  }
  .Career-container .Career-info-container .Career-info-left .Career-summary-container .Career-summary {
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    line-height: 1.1rem;
  }
}

.Career-container .Career-info-container .Career-info-left .Career-education-container {
  margin-top: 25px;
  width: 100%;
}

@media (max-width: 1200px) {
  .Career-container .Career-info-container .Career-info-left .Career-education-container {
    margin-top: 15px;
  }
}

.Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education-title {
  font-weight: 800;
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education-title {
    font-size: 1.3rem;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education-title {
    font-size: 1.2rem;
  }
}

.Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education .Career-education-year {
  width: 15%;
}

.Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education .Career-education-details {
  margin-left: 20px;
  text-wrap: wrap;
}

@media (max-width: 1200px) {
  .Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education {
    font-size: 0.9rem;
    letter-spacing: 0.5px;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-info-container .Career-info-left .Career-education-container .Career-education {
    font-size: 0.9rem;
  }
}

.Career-container .Career-info-container .Career-info-left .Career-downloadButton {
  margin-top: 25px;
  text-decoration: none;
  color: #141414;
  background-color: #F2F2F2;
  font-weight: 800;
  font-size: 1.2rem;
  border: none;
  border-radius: 6px;
  width: 165px;
  height: 45px;
  cursor: pointer;
}

.Career-container .Career-info-container .Career-info-left .Career-downloadButton:hover {
  background-color: white;
}

.Career-container .Career-info-container .Career-info-left .Career-downloadButton:disabled {
  cursor: default;
  background-color: #bbb8b8;
}

@media (max-width: 1200px) {
  .Career-container .Career-info-container .Career-info-left .Career-downloadButton {
    font-size: 0.8rem;
    border-radius: 3px;
    width: 115px;
    height: 31px;
  }
}

@media (max-width: 317px) {
  .Career-container .Career-info-container .Career-info-left .Career-downloadButton {
    font-weight: 600;
    font-size: 0.7rem;
  }
}

.Career-container .Career-info-container .Career-info-left .Career-socialsButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #F2F2F2;
  width: 45px;
  height: 45px;
  font-size: 2rem;
  margin-left: -10px;
  cursor: pointer;
}

.Career-container .Career-info-container .Career-info-left .Career-socialsButton:disabled {
  cursor: default;
}

.Career-container .Career-info-container .Career-info-right {
  width: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-height: 300px;
}

.Career-container .Career-info-container .Career-info-right p {
  text-align: right;
  font-size: 3.2rem;
  font-weight: 800;
  text-transform: uppercase;
  font-family: "Poppins", "Open Sans", sans-serif;
}

@media (max-width: 1200px) {
  .Career-container .Career-info-container .Career-info-right p {
    font-size: 1.8rem;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-info-container .Career-info-right {
    width: 100%;
  }
  .Career-container .Career-info-container .Career-info-right p {
    font-size: 2.8rem;
  }
}

.Career-container .Career-CV-section {
  background-color: #EFEFEF;
  width: 100vw;
  min-height: 100vh;
  margin-top: 5vh;
  color: #141414;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5vh;
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section {
    margin-top: 0vh;
  }
}

.Career-container .Career-CV-section .Career-CV {
  width: 75vw;
  margin-top: 7vh;
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section .Career-CV {
    width: 80vw;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-section-label {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.Career-container .Career-CV-section .Career-CV .Career-CV-exp {
  font-weight: 800;
  font-size: 1.2rem;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-exp {
    margin-top: 25px;
    font-size: 1rem;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-exp-info {
  font-size: 1.2rem;
  margin-top: 25px;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-exp-info {
    font-size: 0.9rem;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-exp-info {
    margin-top: 15px;
    font-size: 0.9rem;
    text-align: justify;
    line-height: 1.3rem;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-exp-list {
  margin-top: 25px;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-exp-list {
    margin-top: 15px;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-exp-list .Career-CV-exp-list-item {
  font-size: 1.2rem;
}

.Career-container .Career-CV-section .Career-CV .Career-CV-exp-list .Career-CV-exp-list-item a {
  color: #141414;
  text-decoration: none;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-exp-list .Career-CV-exp-list-item {
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    line-height: 1.3rem;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-exp-list .Career-CV-exp-list-item {
    font-size: 0.9rem;
    line-height: 1.3rem;
    letter-spacing: 0.6px;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio {
  margin-top: 25px !important;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio {
    margin-top: 0px !important;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio {
    justify-content: center;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item {
  width: 391px;
  height: 209px;
  margin-right: 30px;
  margin-top: 30px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item {
    width: 234.6px;
    height: 125.4px;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item {
    margin-right: 0px;
    width: 273.7px;
    height: 146.3px;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link {
    border-radius: 5px;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link .Career-CV-portfolio-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link .Career-CV-portfolio-img {
    border-radius: 5px;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link .Career-CV-portfolio-text-box {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 38px;
  width: 100%;
  background-color: rgba(36, 36, 36, 0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 0 10px 10px;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link .Career-CV-portfolio-text-box {
    border-radius: 0 0 5px 5px;
    height: 30px;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link .Career-CV-portfolio-text-box .Career-CV-portfolio-text {
  color: white;
  margin-left: 36px;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-item-link .Career-CV-portfolio-text-box .Career-CV-portfolio-text {
    font-size: 0.8rem;
    margin-left: 20px;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 {
  width: 391px;
  height: 209px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: default;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 {
    width: 234.6px;
    height: 125.4px;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 {
    width: 273.7px;
    height: 146.3px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 .Career-CV-portfolio-button {
  background-color: #E20955;
  border-radius: 6px;
  width: 114px;
  height: 32px;
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  border: 1px solid EFEFEF;
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 .Career-CV-portfolio-button:hover {
  background-color: white;
  color: #E20955;
  border: 1px solid #E20955;
}

.Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 .Career-CV-portfolio-text2 {
  margin-top: 7px;
  margin-bottom: 20px;
  color: #141414;
  font-size: 1.6rem;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 .Career-CV-portfolio-text2 {
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-CV-section .Career-CV .Career-CV-portfolio .Career-CV-portfolio-item .Career-CV-portfolio-text-box2 .Career-CV-portfolio-text2 {
    margin-top: 10px;
  }
}

.Career-container .Career-photo-container {
  width: 75vw;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .Career-container .Career-photo-container {
    width: 80vw;
  }
}

@media (max-width: 640px) {
  .Career-container .Career-photo-container {
    flex-direction: column;
    width: 100vw;
  }
}

.Career-container .Career-photo-container .Career-photo {
  width: 50%;
}

@media (max-width: 640px) {
  .Career-container .Career-photo-container .Career-photo {
    width: 100%;
  }
}
