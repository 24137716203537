@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Devanagari:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.Home-container {
  width: 100vw;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  overflow-x: hidden !important;
}

@media (max-width: 640px) {
  .Home-container {
    margin-top: 3.5vh;
  }
}

.Home-container .Home-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 93%;
  font-size: 2.1vw;
  font-weight: 300;
}

.Home-container .Home-hero {
  margin-top: 5vh;
  width: 75vw;
  height: 25vw;
  border: 2px solid black;
  border-radius: 20px;
  position: relative;
}

@media (max-width: 1200px) {
  .Home-container .Home-hero {
    width: 100vw;
    height: 34vw;
    border-radius: 10px;
    border-radius: 0px;
  }
}

@media (max-width: 640px) {
  .Home-container .Home-hero {
    height: 34vw;
    margin-top: 3vh;
  }
}

.Home-container .Home-hero .Home-hero-title {
  position: absolute;
  font-size: 3.8vw;
  bottom: 5%;
  left: 5.2%;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  max-width: 65%;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .Home-container .Home-hero .Home-hero-title {
    bottom: 10%;
    font-size: 1.8rem;
  }
}

@media (max-width: 640px) {
  .Home-container .Home-hero .Home-hero-title {
    font-size: 1.0rem;
  }
}

.Home-container .Home-hero .Home-hero-img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .Home-container .Home-hero .Home-hero-img {
    border-radius: 0px;
  }
}

.Home-container .Home-switcher {
  margin-top: 5vh;
  width: 75vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .Home-container .Home-switcher {
    width: 100vw;
    margin-top: 0vh;
  }
}

.Home-container .Home-switcher .Home-switcher-item {
  width: 45%;
  height: 165px;
  background-color: #1A1A1A;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 800;
  border: 4px black solid;
  cursor: pointer;
  transition: 0.2s;
  color: white;
  text-decoration: none;
  background: linear-gradient(145deg, #171717, #1c1c1c);
  box-shadow: 5px 5px 12px #0a0a0a, -5px -5px 12px #2a2a2a;
  border: none;
}

@media (max-width: 1200px) {
  .Home-container .Home-switcher .Home-switcher-item {
    width: 50%;
    border: 1px black solid;
    border-radius: 0px;
    background: none;
    box-shadow: none;
    border-left: none;
    border-right: none;
  }
}

@media (max-width: 640px) {
  .Home-container .Home-switcher .Home-switcher-item {
    height: 100px;
  }
}

.Home-container .Home-switcher .Home-switcher-item:hover {
  background-color: #242424;
  background: #1A1A1A;
  box-shadow: inset 5px 5px 12px #0a0a0a, inset -5px -5px 12px #2a2a2a;
}

.Home-container .Home-switcher .Home-switcher-item .Home-switcher-title {
  font-size: 2.8rem;
}

@media (max-width: 1200px) {
  .Home-container .Home-switcher .Home-switcher-item .Home-switcher-title {
    font-size: 2.0rem;
  }
}

@media (max-width: 640px) {
  .Home-container .Home-switcher .Home-switcher-item .Home-switcher-title {
    font-size: 1.4rem;
    justify-content: flex-start;
  }
}

.Home-container .Home-switcher .Home-switcher-item .Home-switcher-subtitle {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .Home-container .Home-switcher .Home-switcher-item .Home-switcher-subtitle {
    font-size: 1.2rem;
    margin-top: 2px;
    font-weight: 400;
  }
}

@media (max-width: 640px) {
  .Home-container .Home-switcher .Home-switcher-item .Home-switcher-subtitle {
    font-size: 0.8rem;
    word-wrap: break-word;
  }
}

.Home-container .Home-bucketlist {
  margin-top: 0vh;
  margin-bottom: 5vh;
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .Home-container .Home-bucketlist {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .Home-container .Home-bucketlist {
    width: 100vw;
  }
}

.Home-container .Home-bucketlist .Home-bucketlist-title {
  font-size: 4.8vw;
  font-family: "Alegreya Sans", sans-serif;
}

@media (max-width: 1200px) {
  .Home-container .Home-bucketlist .Home-bucketlist-title {
    font-size: 2.8rem;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 800;
  }
}

@media (max-width: 640px) {
  .Home-container .Home-bucketlist .Home-bucketlist-title {
    font-size: 2.0rem;
  }
}
